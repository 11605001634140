import { RiMapPinUserLine } from "react-icons/ri";
import { Link } from 'react-router-dom';

const HotelBox = ({ hotel }) => {
  return (
    <>
      <div className="w-full p-1.5 bg-white border-2 border-[#F3F3F3] rounded-2xl mb-4">
        <Link to={'/hotels/' + hotel.id}>
          <div className="flex w-full h-full">
            <div className="w-[30%] h-full aspect-[41/47] pe-1">
              <img src={hotel.image} className='w-full h-full object-contain rounded-lg' alt="" />
            </div>
            <div className="w-[70%] h-full ps-1 py-1">
              <h5 className='mb-2 text-sm font-bold leading-[14px] truncate truncate-2'>{hotel.title}</h5>
              <p className='text-[#939393] font-bold text-xs'>
                <RiMapPinUserLine className='inline-block me-1 text-sm -mt-1' />
                <span className='inline'>{hotel.contact_person}, {hotel.address}</span>
              </p>
            </div>
          </div>
        </Link>
      </div>
    </>
  )
}

export default HotelBox