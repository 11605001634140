import { useEffect } from 'react'
import Back from '../Components/Back';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Components/Loader';
import { Link, useParams } from 'react-router-dom';
import { FaLongArrowAltRight, FaRegFilePdf } from 'react-icons/fa';
import { PiMapPinLineBold } from 'react-icons/pi';
import { getHotelDetail } from '../actions/hotelAction';
import { IoMdCall } from 'react-icons/io';

const Hotel = () => {
    const dispatch = useDispatch();
    const { loading, error, hotels } = useSelector((state) => state.hotel_detail);
    const { slug } = useParams();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        const auth_id = user?.id;
        const hotel_id = slug;
        dispatch(getHotelDetail(auth_id, hotel_id));
    }, [dispatch, error])


    return (
        <>
            {
                loading && <Loader />
            }
            <div className="w-full h-full relative">
                <div className="w-full h-[180px] relative z-[1] bottom-rounded-event overflow-hidden">
                    <div className="absolute top-0 left-0 w-full h-full z-[-1] bg-black bg-opacity-15"></div>
                    <div className="absolute top-0 left-0 w-full h-full z-[-2]">
                        <img src={hotels?.image} alt={hotels?.title} className='absolute top-0 left-0 w-full h-full object-cover' />
                    </div>
                    <div className="sm:pt-8 text-white">
                        <Back title={hotels.title || 'Hotel'} />
                    </div>
                </div>
                <div className="inline-block text-xs font-bold shadow-2 px-6 py-2 relative z-[1] -translate-x-1/2 left-1/2 rounded-full top-[-10px]">
                    {/* 100k views */}
                </div>
                <div className="px-4 ">
                    <h4 className='font-bold text-2xl mb-4'>{hotels?.title}</h4>
                    <Link target='_blank' to='https://www.google.com/maps/place/Anantara+Kalutara+Resort/@6.566016,79.9625627,15z/data=!4m9!3m8!1s0x3ae2372a43a9e44b:0xaaad6c7857ea1246!5m2!4m1!1i2!8m2!3d6.566016!4d79.9625627!16s%2Fg%2F11c3_85kf8?entry=ttu'>
                        <div className="inline-flex mb-3">
                            <div className="p-3 bg-[#E0F2ED] rounded-md flex justify-center items-center">
                                <PiMapPinLineBold className='text-[#009368]' />
                            </div>
                            <div className="px-4 py-1">
                                <p className='text-sm font-bold'>{hotels?.address}</p>
                                <p className='text-xs text-[#747688] font-medium'>{hotels?.location_name}</p>
                            </div>
                        </div>
                    </Link>
                    {
                        hotels?.phone &&
                        <Link to={`tel:${hotels?.phone}`}>
                            <div className="inline-flex mb-3">
                                <div className="p-3 bg-[#E0F2ED] rounded-md flex justify-center items-center">
                                    <IoMdCall className='text-[#009368]' />
                                </div>
                                <div className="px-4 py-1">
                                    <p className='text-sm font-bold'>{hotels?.phone}</p>
                                </div>
                            </div>
                        </Link>
                    }
                    {
                        hotels?.pdf &&
                        <div className="my-3 flex justify-between">
                            <h3 className='text-md font-bold'>Download PDF <FaLongArrowAltRight className='inline' /></h3>
                            <Link to={hotels.pdf} target='_blank'>
                                <FaRegFilePdf />
                            </Link>
                        </div>
                    }
                    <div className="my-3">
                        <h3 className='font-bold text-md mb-3'>About {hotels.title || 'Hotel'}</h3>
                        <div className='text-sm text-justify pb-5' dangerouslySetInnerHTML={{ __html: hotels?.content }}>
                        </div>
                    </div>
                    {
                        hotels.media &&
                        <div className="my-3">
                            <h3 className='font-bold text-md mb-3'>Images</h3>
                            <div className="flex flex-wrap">
                                {
                                    hotels.media.map(function (item, index) {
                                        return <div key={index} className="basis-1/2 px-1.5 py-1.5">
                                            <div className="galery-box-shadow h-[100px] border-2 border-[#eee]">
                                                <img src={item.file} className='w-full h-full object-cover' />
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Hotel