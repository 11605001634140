import { useEffect, useState } from 'react';
import Back from '../Components/Back';
import Turtle from '../images/KickOff2024.png';
import Loader from '../Components/Loader';
import { FaRegFilePdf } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';


const Profile = () => {
    const navigate = useNavigate();
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(true);
    const user = localStorage.getItem("user");
    useEffect(() => {
        const data = JSON.parse(user);
        // console.log((data?.visa).split(''));
        setProfile(data);
        setLoading(false);
    }, [])

    const Logout = function(){
        localStorage.removeItem('user');
        navigate('/login');
    }

    return (
        <>
            {
                loading &&
                <Loader />
            }
            <div className='relative w-full h-full pt-6 sm:pt-10'>
                <Back title="Profile" />
                <div className='py-2'>

                </div>
                <div className='w-24 h-24 rounded-full shadow-2xl mx-auto overflow-hidden'>
                    <img src={profile?.image || Turtle} alt='Profile' className='w-full h-full object-cover' />
                </div>
                <div className='px-4 py-3'>
                    <p className='text-md font-bold'>Name : <span className='font-medium'>{profile?.name}</span></p>
                    <p className='text-md font-bold my-3'>Phone : <span className='font-medium'>{profile?.phone}</span></p>
                    <p className='text-md font-bold my-3'>Email : <span className='font-medium'>{profile?.email}</span></p>
                </div>
                <div className='px-2'>
                    <div className='flex flex-wrap'>
                        {
                            profile?.visa &&
                                <div className='w-full px-1.5 py-1.5'>
                                    <h6 className='font-bold mb-3'>Visa</h6>
                                    <Link to={profile?.visa} target='_blank' className='w-full border-2 border-[#eee] h-32 flex flex-col justify-center items-center'>
                                        <FaRegFilePdf className='text-5xl text-[#009368]' />
                                        <p className='text-lg font-bold text-[#'>Download PDF</p>
                                    </Link>
                                </div> 
                                // <div className='w-full px-1.5 py-1.5'>
                                //     <div className='border-2 w-full border-[#eee] text-center'>
                                //         <h6 className='font-bold'>Visa</h6>
                                //         <img src={Turtle} alt='visa' className='w-full h-40 object-cover' />
                                //     </div>
                                // </div>
                        }
                        {/* <div className='basis-1/2 px-1.5 py-1.5'>
                            <div className='border-2 border-[#eee] text-center'>
                                <h6 className='font-bold'>Ticket</h6>
                                <div className='w-full h-20 flex justify-center items-center'>
                                    <FaRegFilePdf className='text-3xl text-[#009368]' />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className='px-2 text-center'>
                    <div onClick={Logout} className='inline-block px-4 py-2 bg-red-500 text-white rounded-md mt-4 cursor-pointer'>Logout</div>
                </div>
            </div>
        </>
    )
}

export default Profile