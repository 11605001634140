import { Outlet } from 'react-router-dom';
import Frame  from '../images/frame.png';
import FrameCamera  from '../images/frame-camera.png';
import NavigationBar from './NavigationBar';
import { useSelector } from "react-redux";
import Loader from './Loader';
import { useEffect } from 'react';
const Theme = () => {
    useEffect(()=>{
        // window.addEventListener("beforeunload",(e)=>{
        //     e.preventDefault();
        //     e.stopImmediatePropagation();
        // })
        
    },[])
    const {loading} = useSelector((state)=>state.user);
  return (
    <>
    <div className="flex justify-center items-center sm:min-h-screen sm:bg-black sm:py-10">
        <div className='w-full h-screen sm:h-auto sm:w-[320px] sm:aspect-[9/19.5] relative'>
            <div className='absolute hidden sm:block opacity-0 sm:opacity-100 top-0 left-0 w-full h-full'>
                <img src={Frame} alt='Frame' className='absolute top-0 left-0 w-full  aspect-[9/19.5]' />
            </div>
            <div className="sm:py-[1.1rem] sm:px-[1.2rem] w-full h-full relative z-[1]">
                <div className="bg-white w-full h-full sm:rounded-[40px] overflow-hidden">
                    <img src={FrameCamera} alt='Mobile Camera' className='absolute opacity-0 sm:opacity-100 top-[3%] left-1/2 z-10 translate-x-[-50%]' />
                    <div className="relative w-full h-full">
                        <div className="w-full screen-set overflow-y-scroll">
                            <Outlet />
                        </div>
                        <NavigationBar />
                        {(loading) ? <Loader /> : ''}
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Theme