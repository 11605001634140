import {
    ALL_IMAGE_FAIL,
    ALL_IMAGE_REQUEST, 
    ALL_IMAGE_SUCCESS,
    CLEAR_ERRORS,
    UPLOAD_IMAGE_FAIL,
    UPLOAD_IMAGE_REQUEST,
    UPLOAD_IMAGE_SUCCESS
} from "../constants/galleryConstants";

export const galleryReducer = (state = { images: [] }, action) => {
    switch (action.type) {
        case UPLOAD_IMAGE_REQUEST:
        case ALL_IMAGE_REQUEST:
            return {
                loading: true,
                images: []
            };
        case ALL_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                images: action.payload,
            };
        case UPLOAD_IMAGE_SUCCESS:
            return {
                ...state,
                loading:false,
                success: action.payload.message,
            };
        case UPLOAD_IMAGE_FAIL:
        case ALL_IMAGE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};