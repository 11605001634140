import { useEffect, useState } from 'react'
import Back from '../Components/Back'
import { PiPlusBold } from 'react-icons/pi'
import { IoClose } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../Components/Loader';
import { clearErrors, getAllImages, uploadGallery } from '../actions/galleryAction';
import AlertHandler from '../Components/Alert';
import { FaDownload } from 'react-icons/fa6';
import axios from '../actions/axios';
import { MdDeleteForever } from 'react-icons/md';
import { ALL_IMAGE_REQUEST } from '../constants/galleryConstants';
import { Link, useParams } from 'react-router-dom';

const Gallery = () => {
  const {date} = useParams();
  const dispatch = useDispatch();
  const { loading, error, success, images } = useSelector((state) => state.gallery);

  const [preview, setPreview] = useState({ active: false });
  const [upload, setUpload] = useState({});
  function showImage(e) {
    const mediaid = e.target.getAttribute('mediaid');
    setPreview({
      ...preview,
      active: true,
      src: e.target.src,
      alt: e.target.alt,
      mediaid
    })
  }
  

  function closePreview() {
    setPreview({ ...preview, active: false });
  }


  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
    const user = JSON.parse(localStorage.getItem('user'));
    if(user){
      const auth_id = user.id;
      dispatch(getAllImages(auth_id,date));
    }
  }, [dispatch, success])

  function uploadFile(e) { 
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem('user'));
    const auth_id = user.id;
    let files = e.target.files[0];
    setUpload(files);
    const formData = new FormData();
    formData.append('image', files);
    formData.append('auth_id', auth_id);
    dispatch(uploadGallery(formData));
    setUpload(undefined);
  }

  const deleteImage = async() =>{
    try {
      setPreview({
        ...preview,
        active:false
      })
      const user = JSON.parse(localStorage.getItem('user'));
      if(user && preview.mediaid){
        dispatch({type: ALL_IMAGE_REQUEST});
        const auth_id = user.id;
        const media_id = preview.mediaid
        const {data} = await axios.post('delete-photo',{
          auth_id,media_id
        })
        if (data?.status === false) {
            dispatch(getAllImages(auth_id));
            return
        }
        if (data?.status === true) {
            dispatch(getAllImages(auth_id));
            return
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      {
        loading && <Loader />
      }
      {
        error && <AlertHandler status={error?.status} message={error?.errors?.image} />
      }
      <div className='relative w-full h-full sm:pt-10'>
        <Back title={date} />
        <div className="w-full box-wrap overflow-y-scroll pb-3">
          <div className="flex flex-wrap">
            {
              images.map(function (item) {
                return (
                  <div key={item?.id} className="basis-1/2 px-1.5 py-1.5">
                    <div className="galery-box-shadow h-[100px] border-2 border-[#eee]">
                      <img className='rounded-1xl w-full h-full object-contain' mediaid={item?.id} onClick={showImage} src={item.url} alt={item?.event?.title} />
                    </div>
                    {
                      item?.user?.name &&
                    <p className='text-xs text-center font-bold'>{item?.user?.name}</p>
                    }
                  </div>
                )
              })}
          </div>
        </div>
        <label htmlFor="galeryImageUpload">
          <div className="absolute bottom-6 rounded-full right-4 w-12 h-12 flex justify-center items-center bg-[#009368]">
            <input type="file" accept='image/*;capture=camera' id='galeryImageUpload' className='hidden' value={upload?.value || undefined} onChange={uploadFile} />
            <PiPlusBold className='text-white text-2xl' />
          </div>
        </label>
        <div className={`absolute z-30 top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-60 ${(preview.active) ? '' : 'hidden'}`}>
          <div className="w-full mx-5 relative">
            <div className='absolute w-8 h-8 bg-white -top-3 -left-3 flex justify-center items-center shadow-xl z-[2] rounded-full'>
              <Link to={preview?.src} target='_blank'>
                <FaDownload />
              </Link>
            </div>
            <div className='absolute w-8 h-8 bg-white -top-3 left-1/2 -translate-x-1/2 flex justify-center items-center shadow-xl z-[2] rounded-full'>
              <div onClick={deleteImage}>
                <MdDeleteForever />
              </div>
            </div>
            <div onClick={closePreview} className="absolute w-8 h-8 bg-white -top-3 -right-3 flex justify-center items-center shadow-xl z-[2] rounded-full">
              <IoClose className='text-lg' />
            </div>
            <img className='w-full bg-white max-h-96 object-contain p-3 rounded-xl' src={preview?.src} alt={preview?.alt} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Gallery