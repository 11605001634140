import React, { useEffect } from 'react';
import Back from '../Components/Back';
import Turtle from '../images/KickOff2024.png';
import {useDispatch,useSelector} from 'react-redux';
import Loader from '../Components/Loader';
import { clearErrors, getAllMembers } from '../actions/memberAction';
import { Link } from 'react-router-dom';

const Member = () => {
  const dispatch = useDispatch();
  const {loading,error,members} = useSelector(state=>state.member);
  useEffect(()=>{
    const user = JSON.parse(localStorage.getItem('user'));
    const auth_id = user.id;
    if (error) {
        dispatch(clearErrors);
    }
    dispatch(getAllMembers(auth_id));
  },[dispatch,error])
  return (
    <>
    { loading && <Loader /> }
    <div className='relative w-full h-full sm:pt-10'>
        <Back title="KOC Members" />
        <div className="w-full box-wrap overflow-y-scroll px-4 pb-3">
          <div className="flex flex-wrap">
            {
              members.map((item)=>{
                return <div key={item?.id} className="basis-1/2 px-1.5 py-1.5">
                  <div className="galery-box-shadow h-full border-2 border-[#eee]">

                    <img className='w-full h-[125px] object-cover object-top' src={item.image|| Turtle} alt='' />
                    <div className="py-2 px-2 text-center">
                      <h6 className='text-xs text-center font-medium'>{item.name}</h6>
                      <Link to={`tel:${item?.phone}`} className='text-xs font-medium'>{item?.phone}</Link>
                    </div>
                  </div>
                </div>
              })
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default Member