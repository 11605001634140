import { 
    ALL_HOTELS_FAIL, 
    ALL_HOTELS_REQUEST, 
    ALL_HOTELS_SUCCESS, 
    CLEAR_ERRORS, 
    HOTELS_FAIL, 
    HOTELS_REQUEST,
    HOTELS_SUCCESS
} from "../constants/hotelConstrants";
import axios from "./axios";

export const getAllHotel = (auth_id) => async (dispatch) => {
    try {
        dispatch({ type:ALL_HOTELS_REQUEST})
        const config = { headers: { "Content-Type": "application/json" }, params: { auth_id } };

        const { data } = await axios.get(
            `/hotels`,
            config
        );

        if (data?.status === true) {
            dispatch({ type: ALL_HOTELS_SUCCESS, payload: data?.data });
            return
        }
        if (data?.status === false) {
            dispatch({ type: ALL_HOTELS_FAIL, payload: data });
            return
        }
        
    } catch (error) {
        dispatch({ type:ALL_HOTELS_FAIL,payload: error.response.data.message })
    }
}
export const getHotelDetail = (auth_id,hotel_id) => async (dispatch) => {
    try {
        dispatch({ type:HOTELS_REQUEST})
        const config = { headers: { "Content-Type": "application/json" }, params: { auth_id,hotel_id } };

        const { data } = await axios.get(
            `/hotel/view`,
            config
        );

        if (data?.status === true) {
            dispatch({ type: HOTELS_SUCCESS, payload: data?.data });
            return
        }
        if (data?.status === false) {
            dispatch({ type: HOTELS_FAIL, payload: data });
            return
        }
        
    } catch (error) {
        dispatch({ type:HOTELS_FAIL,payload: error.response.data.message })
    }
}

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
    setTimeout(() => {
        dispatch({ type: CLEAR_ERRORS });
    }, 5000);
};