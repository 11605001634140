import React from 'react'
import { IoArrowBack } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom';

const Back = ({title}) => {
    const navigate = useNavigate();
    function goBack(){
        navigate(-1);
    }
  return (
    <>
    <div className="w-full h-[45px] flex items-center py-2 px-3" onClick={goBack}>
        <IoArrowBack className='me-1 cursor-pointer' />
        <h4 className='text-lg font-bold'>{title}</h4>
    </div>
    </>
  )
}

export default Back