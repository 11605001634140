import {
    CLEAR_ERRORS,
    LOAD_MEMBER_FAIL,
    LOAD_MEMBER_REQUEST, 
    LOAD_MEMBER_SUCCESS
} from "../constants/memeberConstants";

export const memberReducer = (state = { members: [] }, action) => {
    switch (action.type) {
        case LOAD_MEMBER_REQUEST:
            return {
                loading: true,
                members: []
            };
        case LOAD_MEMBER_SUCCESS:
            return {
                ...state,
                loading: false,
                members: action.payload,
            };
        case LOAD_MEMBER_FAIL:
            return {
                loading: false,
                members: [],
                error: action.payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};