import {
    ALL_IMAGE_FAIL,
    ALL_IMAGE_REQUEST,
    ALL_IMAGE_SUCCESS,
    CLEAR_ERRORS,
    UPLOAD_IMAGE_FAIL,
    UPLOAD_IMAGE_REQUEST,
    UPLOAD_IMAGE_SUCCESS
} from "../constants/galleryConstants";
import axios from "./axios";


export const getAllImages = (auth_id,date) => async(dispatch) => {
    try {
        dispatch({ type: ALL_IMAGE_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, params: { auth_id,date } };
        const { data } = await axios.get(
            `/gallery`,
            config
        );
        if (data?.status === false) {
            dispatch({ type: ALL_IMAGE_FAIL, payload: data });
            return
        }
        dispatch({ type: ALL_IMAGE_SUCCESS, payload: data?.data });
    } catch (error) {
        dispatch({ type: ALL_IMAGE_FAIL, payload: error.response.data.message });
    }
};

export const uploadGallery = (form_data) => async(dispatch) => {
    try {
        dispatch({type: UPLOAD_IMAGE_REQUEST});
        const config = { headers: { "Content-Type": "multipart/form-data" } };
        const { data } = await axios.post(
            `/upload-event-photo`,
            form_data,
            config
        );
        console.log(data);
        if (data?.status === false) {
            dispatch({type: UPLOAD_IMAGE_FAIL, payload: data})
            return;
        }
        if (data?.status === true) {
            dispatch({type: UPLOAD_IMAGE_SUCCESS, payload: {message : data?.message} })
            return;
        }
    } catch (error) {
        dispatch({type: UPLOAD_IMAGE_FAIL, payload: error.response.data.message})
    }
}


// Clearing Errors
export const clearErrors = () => async (dispatch) => {
    setTimeout(() => {
        dispatch({ type: CLEAR_ERRORS });
    }, 5000);
};