import {
    ALL_EVENT_FAIL,
    ALL_EVENT_REQUEST,
    ALL_EVENT_SUCCESS,
    ALL_PAGE_FAIL,
    ALL_PAGE_REQUEST,
    ALL_PAGE_SUCCESS,
    CLEAR_ERRORS,
    EVENT_FAIL,
    EVENT_REQUEST,
    EVENT_SUCCESS
} from '../constants/pageConstants';

export const eventReducer = (state = { events: [] }, action) => {
    switch (action.type) {
        case ALL_EVENT_REQUEST:
            return {
                loading: true,
                events: []
            };
        case ALL_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                events: action.payload,
            };

        case ALL_EVENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};
export const eventDetailReducer = (state = { events: {} }, action) => {
    switch (action.type) {
        case EVENT_REQUEST:
            return {
                loading: true,
                events: {}
            };
        case EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                events: action.payload,
            };

        case EVENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};
export const pageReducer = (state = { pages: [] }, action) => {
    switch (action.type) {
        case ALL_PAGE_REQUEST:
            return {
                loading: true,
                pages: []
            };
        case ALL_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                pages: action.payload,
            };

        case ALL_PAGE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};