import React, { useEffect, useState } from 'react'
import Back from '../Components/Back';
import { Link } from 'react-router-dom';
import { FaRegFilePdf } from 'react-icons/fa6';
import Loader from '../Components/Loader';

const ETA = () => {

  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const user = localStorage.getItem("user");
  useEffect(() => {
    const data = JSON.parse(user);
    // console.log((data?.visa).split(''));
    setProfile(data);
    setLoading(false);
  }, [])
  return (
    <>
      {
        loading && <Loader />
      }
      <div className='relative w-full h-full pt-6 sm:pt-10'>
        <Back title="ETA" />
        <div className='px-2'>
          <div className='flex flex-wrap'>
            {
              profile?.visa &&
              <div className='w-full px-1.5 py-1.5'>
                <h6 className='font-bold mb-3'>Visa</h6>
                <Link to={profile?.visa} target='_blank' className='w-full border-2 border-[#eee] h-32 flex flex-col justify-center items-center'>
                  <FaRegFilePdf className='text-5xl text-[#009368]' />
                  <p className='text-lg font-bold'>Download PDF</p>
                </Link>
              </div>
              // <div className='w-full px-1.5 py-1.5'>
              //     <div className='border-2 w-full border-[#eee] text-center'>
              //         <h6 className='font-bold'>Visa</h6>
              //         <img src={Turtle} alt='visa' className='w-full h-40 object-cover' />
              //     </div>
              // </div>
            }
            {/* <div className='basis-1/2 px-1.5 py-1.5'>
                    <div className='border-2 border-[#eee] text-center'>
                        <h6 className='font-bold'>Ticket</h6>
                        <div className='w-full h-20 flex justify-center items-center'>
                            <FaRegFilePdf className='text-3xl text-[#009368]' />
                        </div>
                    </div>
                </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ETA