import React, { useEffect, useState } from 'react'
import Back from '../Components/Back'
import { useLocation, useParams } from 'react-router-dom';
import Loader from '../Components/Loader';
import axios from '../actions/axios';

const NotificationInner = () => {
  const [loader,setLoader] = useState(true);
  const [notificationData,setNotificationData] = useState({});
  const {notification_id} = useParams();
  useEffect(()=>{
    (async()=>{
      try {
        setLoader(true);
        const user = JSON.parse(localStorage.getItem('user'));
        if(user){
          const auth_id = user.id;
          const { data } = await axios.get(
            '/notificationSingle',
            {
              params:{
                auth_id,
                notification_id
              }
            }
          );
          if(data?.status === true){
            setNotificationData({
              ...notificationData,
              item: data?.data
            })
            setLoader(false);
          }
        }
      } catch (err) {
        setLoader(true);
        console.log(err);
      }
    })()
  },[])
  return (
    <>
    {
      loader && <Loader />
    }
    <div className='relative w-full h-full pt-6 sm:pt-10'>
        <Back title="Notification" />
        <div className='w-full px-4'>
            <div className='border-2 py-1 px-3 rounded-lg'>
                <h5 className='text-sm font-bold'>{notificationData?.item?.title}</h5>
                <div className='font-semibold text-wrap break-words' dangerouslySetInnerHTML={{__html: notificationData?.item?.content}}></div>
            </div>
        </div>
    </div>
    
    </>
  )
}

export default NotificationInner