import axios from "./axios";
import { 
  CLEAR_ERRORS,
    LOGIN_FAIL,
    LOGIN_REQUEST, 
    LOGIN_SUCCESS,
} from '../constants/userConstants';

export const login = (email, password) => async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });
  
      const config = { headers: { "Content-Type": "application/json" } };
  
      const { data } = await axios.post(
        `/auth/login`,
        { email, password },
        config
      );
      if (data?.status === false) {
        dispatch({ type: LOGIN_FAIL, payload: data });
        return
      }
      localStorage.setItem('user',JSON.stringify(data?.data?.user));
      dispatch({ type: LOGIN_SUCCESS, payload: data?.data?.user });
    } catch (error) {
      dispatch({ type: LOGIN_FAIL, payload: error.response.data.message });
    }
  };

  
// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  setTimeout(() => {
    dispatch({ type: CLEAR_ERRORS });
  }, 5000);
};
