import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const ProtectedRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(()=>{
    const user = localStorage.getItem('user');
    if (!user) {
      navigate('/login');
    }
    if (user && location.pathname === '/') {
      navigate('/home');
    }
  },[])
  return (
    <>
      <Outlet />
    </>
  );
};

export default ProtectedRoute;
