import React, { useEffect } from 'react';
import Back from '../Components/Back';
import { useDispatch, useSelector } from 'react-redux';
import { getEvent } from '../actions/pageAction';
import EventBox from "../Components/EventBox";
import Loader from '../Components/Loader';

const Events = () => {
  const dispatch = useDispatch();
  
  const { loading,error,events } = useSelector(
    (state) => state.event
  );
  
  useEffect(()=>{
    const user = JSON.parse(localStorage.getItem('user'));
    if(user){
      const auth_id = user.id;
      dispatch(getEvent(auth_id))
    }
    
  },[dispatch,error])


  return (
    <>
    {
      loading && <Loader />
    }
    <div className='relative w-full h-full pt-6 sm:pt-10 px-3'>
        <Back title="Events" />
        {
          events &&
          events.map((item)=>{
            return <EventBox key={item.id} event={item} />
          })
        }
      </div>
    </>
  )
}

export default Events