import axios from "./axios";
import {
  ALL_EVENT_FAIL,
  ALL_EVENT_REQUEST,
  ALL_EVENT_SUCCESS,
  ALL_PAGE_FAIL,
  ALL_PAGE_REQUEST,
  ALL_PAGE_SUCCESS,
  EVENT_FAIL,
  EVENT_REQUEST,
  EVENT_SUCCESS
} from '../constants/pageConstants';

export const getEvent = (auth_id) => async (dispatch) => {
  try {
    dispatch({ type: ALL_EVENT_REQUEST });

    const config = { headers: { "Content-Type": "application/json" }, params: { auth_id } };

    const { data } = await axios.get(
      `/events`,
      config
    );
    if (data?.status === false) {
      dispatch({ type: ALL_EVENT_FAIL, payload: data });
      return
    }
    dispatch({ type: ALL_EVENT_SUCCESS, payload: data?.data });
  } catch (error) {
    dispatch({ type: ALL_EVENT_FAIL, payload: error.response.data.message });
  }
};
export const getSingleEvent = (auth_id,event_id) => async (dispatch) => {
  try {
    dispatch({ type: EVENT_REQUEST });

    const config = { headers: { "Content-Type": "application/json" }, params: { auth_id,event_id } };

    const { data } = await axios.get(
      `/event/view`,
      config
    );
    if (data?.status === false) {
      dispatch({ type: EVENT_FAIL, payload: data });
      return
    }
    dispatch({ type: EVENT_SUCCESS, payload: data?.data });
  } catch (error) {
    dispatch({ type: EVENT_FAIL, payload: error.response.data.message });
  }
};

export const getPages = (auth_id) => async(dispatch) =>{
  try {
    dispatch({ type: ALL_PAGE_REQUEST })
    const config = { headers: { "Content-Type": "application/json" }, params: { auth_id } };
    const { data } = await axios.get(
      `/pages`,
      config
    );
    
    if (data?.status === false) {
      dispatch({ type: ALL_PAGE_FAIL, payload: data });
      return
    }

    dispatch({ type: ALL_PAGE_SUCCESS, payload: data?.data });
  } catch (error) {
    dispatch({ type:ALL_PAGE_FAIL,payload: error.response.data.message })
  }
}