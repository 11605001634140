import React, { useEffect } from 'react';
import {useSelector,useDispatch} from 'react-redux';
import Back from '../Components/Back';
import Loader from '../Components/Loader';
import { FaCalendarAlt, FaLongArrowAltRight, FaRegFilePdf } from 'react-icons/fa';
import { PiMapPinLineBold } from 'react-icons/pi';
import { Link, useParams } from 'react-router-dom';
import { getSingleEvent } from '../actions/pageAction';

const Event = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { loading, error, events } = useSelector((state) => state.event_detail);
  
  useEffect(()=>{
    const user = JSON.parse(localStorage.getItem('user'));
    dispatch(getSingleEvent(user?.id,params?.id))
  },[dispatch,error])


  return (
    <>
    {
      loading && <Loader />
    }
      <div className="w-full h-full relative">
        <div className="w-full h-[180px] relative z-[1] bottom-rounded-event overflow-hidden">
          <div className="absolute top-0 left-0 w-full h-full z-[-1] bg-black bg-opacity-25"></div>
          <div className="absolute top-0 left-0 w-full h-full z-[-2]">
            <img src={events?.image} alt={events?.title} className='absolute top-0 left-0 w-full h-full object-cover' />
          </div>
          <div className="sm:pt-6 text-white">
            <Back title="Event" />
          </div>
        </div>
        <div className="inline-block text-xs font-bold shadow-2 px-6 py-2 relative z-[1] -translate-x-1/2 left-1/2 rounded-full top-[-10px]">
          {/* 100k views */}
        </div>
        <div className="px-4 ">
          <h4 className='font-bold text-2xl mb-4'>{events?.title}</h4>
          <div className="inline-flex mb-3">
            <div className="p-3 bg-[#E0F2ED] rounded-md flex justify-center items-center">
              <FaCalendarAlt className='text-[#009368]' />
            </div>
            <div className="px-4 py-1">
              <p className='text-[0.8rem] font-bold'>{new Date(events?.start_at).toLocaleDateString('en-us',{dateStyle:'medium'})} - {new Date(events?.end_at).toLocaleDateString('en-us',{dateStyle:'medium'})}</p>
              <p className='text-xs text-[#747688] font-medium'>{new Date(events?.start_at).toLocaleDateString('en-us',{weekday:'long'})}, {new Date(events?.start_at).toLocaleString('en-us',{hour:'numeric',minute:'numeric'})} - {new Date(events?.end_at).toLocaleString('en-us',{hour:'numeric',minute:'numeric'})}</p>
            </div>
          </div>
          <div className="inline-flex mb-3">
            <div className="p-3 bg-[#E0F2ED] rounded-md flex justify-center items-center">
              <PiMapPinLineBold className='text-[#009368]' />
            </div>
            <div className="px-4 py-1">
              <p className='text-sm font-bold'>{events?.location_name}</p>
              <p className='text-xs text-[#747688] font-medium'>{events?.address}</p>
            </div>
          </div>
          {/* {
            events?.pdf
             && 
          <div className="my-3 flex justify-between">
            <h3 className='text-md font-bold'>Download PDF <FaLongArrowAltRight className='inline' /></h3>
            <Link to={events?.pdf} target='_blank'>
                <FaRegFilePdf />
            </Link>
          </div>
          } */}
          <div className="my-3">
            <h3 className='font-bold text-md mb-3'>About Event</h3>
            <div className='text-sm text-justify pb-5' dangerouslySetInnerHTML={{ __html: events?.description }}>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Event