import React from 'react';
import { NavLink } from 'react-router-dom';
import { IoHome, IoHomeOutline } from "react-icons/io5";
import { FaCircleUser, FaImage, FaRegCircleUser, FaRegImage } from 'react-icons/fa6';
import { MdNotifications, MdOutlineNotificationImportant } from 'react-icons/md';

const NavigationBar = () => {
    return (
        <>
            <div className="absolute bottom-0 left-0 w-full h-[55px] bottom-nav-shadow mt-3 bg-white z-20">
                <div className="flex w-full h-full">
                    <div className="w-full h-full flex justify-center items-center">
                        <NavLink to="/home" className={({ isActive }) => isActive ? "active" : ""}>
                            {({ isActive }) => (
                                (isActive) ? <IoHome className='text-[#009368] text-xl' /> : <IoHomeOutline className='text-[#009368] text-xl' />
                            )}
                        </NavLink>
                    </div>
                    <div className="w-full h-full flex justify-center items-center">
                        <NavLink to="/gallery">
                            {({ isActive }) => (
                                isActive ? <FaImage className='text-[#009368] text-xl' /> : <FaRegImage className='text-[#009368] text-xl' />
                            )}
                        </NavLink>
                    </div>
                    <div className="w-full h-full flex justify-center items-center">
                        <NavLink to='/notification'>
                            
                            {({ isActive }) => (
                                isActive ? <MdNotifications className='text-[#009368] text-xl' /> : <MdOutlineNotificationImportant className='text-xl text-[#009368]' />
                            )}
                        </NavLink>
                    </div>
                    <div className="w-full h-full flex justify-center items-center">
                        <NavLink to="/profile">
                            {({ isActive }) => (
                                isActive ? <FaCircleUser className='text-[#009368] text-xl' /> : <FaRegCircleUser className='text-[#009368] text-xl' />
                            )}
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavigationBar