import { RiMapPinUserLine } from "react-icons/ri";
import { Link } from 'react-router-dom';
import TurtleImg from '../images/KickOff2024.png';
const EventBox = ({ event }) => {
  return (
    <>
    
      <div className="w-full p-1.5 bg-white border-2 border-[#F3F3F3] rounded-2xl mb-4">
        <Link to={'/events/' + event?.id}>
          <div className="flex w-full h-full">
            <div className="w-[30%] h-full aspect-[41/47] pe-1">
              <img src={event?.image || TurtleImg} className='w-full h-full object-contain rounded-lg' alt={event?.title} />
            </div>
            <div className="w-[70%] h-full ps-1 py-1">
              <p className='text-xs mb-1 font-semibold text-[#009368]'>
                <span>{new Date(event?.start_at).toLocaleDateString('en-us',{ weekday: 'short', month: "long", day: "numeric", hour: 'numeric', minute: 'numeric' })}</span>
              </p>
              <h5 className='mb-2 text-sm font-bold leading-[14px] truncate truncate-2'>{event?.title}</h5>
              <p className='text-[#939393] font-bold text-xs'>
                <RiMapPinUserLine className='inline-block me-1 text-sm -mt-1' />
                <span className='inline'>{event?.location_name}, {event?.address}</span>
              </p>
            </div>
          </div>
        </Link>
      </div>
    </>
  )
}

export default EventBox