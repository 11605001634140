import React from 'react';
import Back from '../Components/Back';
import Banner from '../images/tavel_banner.png';

const Travel = () => {
  return (
    <>
    <div className='relative w-full h-full pt-6 sm:pt-10'>
      <Back title="Travel Advisory" />
      <img src={Banner} alt='Travel Banner' />
        {/* <div className="py-5 px-4 text-sm font-medium text-[#747688]">
          <div className='mb-3'>
            <p className='text-[#222] font-bold mb-1'>Common Advisory for all :</p>
            <ul className='list-disc ps-5'>
              <li className='my-2'>Associates have to arrange their local travel in India (to & from airport). Fare for same can be reimbursed as General Expense.</li>
              <li className='my-2'><b className='text-[#444]'>Associates travelling from nearby cities in India to the Hub Airport need to raise BTA </b> (For example - Associate based in Chandigarh/Ambala/etc. will arrange his/her travel to Delhi airport and back to base town through BTA). Normal process for BTA to be followed for same. </li>
              <li className='my-2'><b className='text-[#444]'>27th April Airport meal cost upto INR 1000</b> can be claimed as General Expense (Under Individual Meal head on Concur)</li>
              <li className='my-2'><b className='text-[#444]'>International flights have a baggage limit of 30 Kgs,</b> any additional baggage cost to be borne by the associates.</li>
              <li className='my-2'>Any official local travel in Colombo between 24th Apr to 27th Apr will be arranged by Terumo.</li>
            </ul>
          </div>
          <div className="mb-3">
            <p><b className='text-black'>Thank you for your understanding & cooperation.</b></p>
            <p className='italic'><b className='text-black'>Stuti !! :)</b></p>
            <p className='italic'>Kickoff 2024 Team</p>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default Travel