import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./Route/ProtectedRoute";
import Theme from "./Components/Layout";
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import Events from "./Pages/Events";
import Event from "./Pages/Event";
import Gallery from "./Pages/Gallery";
import Member from "./Pages/Member";
import Agenda from "./Pages/Agenda";
import Contact from "./Pages/Contact";
import Travel from "./Pages/Travel";
import Hotel from "./Pages/Hotel";
import Hotels from "./Pages/Hotels";
import Video from "./Pages/Video";
import { Dress } from "./Pages/Dress";
import Profile from "./Pages/Profile";
import Notification from "./Pages/Notification";
import GalleryFolder from "./Pages/galleryFolder";
import ETA from "./Pages/ETA";
import NotificationInner from "./Pages/NotificationInner";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Theme />}>
          <Route path="*" element={<Login />} />
          <Route path="/login" element={<Login />} />

          <Route path="/" element={<ProtectedRoute />} >
            <Route path="/home" element={<Home />} />
          </Route>
          <Route path="/" element={<ProtectedRoute />} >
            
            <Route path="/events" element={<Events />} />
            <Route path="/events/:id" element={<Event />} />
            <Route path="/hotels" element={<Hotels />} />
            <Route path="/hotels/:slug" element={<Hotel />} />
            <Route path="/gallery" element={<GalleryFolder />} />
            <Route path="/gallery/:date" element={<Gallery />} />
            <Route path="/member" element={<Member />} />
            <Route path="/agenda" element={<Agenda />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/dress-code" element={<Dress />} />
            <Route path="/travel-advisory" element={<Travel />} />
            <Route path="/videos" element={<Video />} />
            <Route path="/eta" element={<ETA />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/notification/:notification_id" element={<NotificationInner />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
