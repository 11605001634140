import React from 'react';
import LoaderImg from '../images/Logo.png';
    
function Loader() {
    return (
    <div className="absolute top-0 left-0 w-full h-full bg-white z-[9] flex justify-center items-center">
        <div className="w-full flex flex-col justify-center items-center px-5">
            <img src={LoaderImg} alt="logo" />
            <span className='loader mt-5'></span>
        </div>
    </div>
    );
}
    
export default Loader;