import Back from '../Components/Back';
import AgendaBanner from '../images/agenda_banner.png';
const Agenda = () => {
  return (
    <>

      <div className='relative w-full h-full pt-6 sm:pt-10'>
        <Back title="Agenda" />
        <img className='mt-3' src={AgendaBanner} />
        <div className="w-full ">
          <div className="w-full overflow-x-scroll rounded-2xl">
            <table className="table caption-top agenda-table table-bordered bg-[#0f5b3d] text-white text-[0.6rem]">
              <thead>
                <tr className="table-head1">
                  <th scope="col" className='font-bold' colSpan="4"><div className='heading'>DAY 1,APRIL 24<sup>th</sup></div></th>

                </tr>
                <tr className="table-head font-bold">

                  <th scope="col" className='font-extrabold'>TIME</th>
                  <th scope="col" className='event_row font-extrabold'>EVENTS</th>
                  <th scope="col" className='font-extrabold'>VENUE</th>
                  <th scope="col" className='font-extrabold'>DRESS CODE</th>
                </tr>
              </thead>
              <tbody className="table-col">
                <tr>
                  <th scope="row">17:30:00</th>
                  <td>HIGH TEA</td>
                  <td>BALLROOM</td>
                  <td>BUSINESS CASUALS</td>
                </tr>
                <tr>
                  <th scope="row">18:30:00</th>
                  <td>THEME REVEAL</td>
                  <td>BALLROOM</td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">21:00:00</th>
                  <td>DINNER & COCKTAIL</td>
                  <td>BALLROOM</td>
                  <td></td>
                </tr>
              </tbody>
              <thead>
                <tr className="table-head1">
                  <th scope="col" colSpan="4"><div className='heading'>DAY 2,APRIL 25<sup>th</sup></div></th>
                </tr>
              </thead>
              <tbody className="table-col">
                <tr>
                  <th scope="row">9:00:00</th>
                  <td>TERUMO GROUP PHOTO</td>
                  <td>AVANI JETTY</td>
                  <td>TERUMO TSHIRT & JACKET</td>
                </tr>
                <tr>
                  <th scope="row">9:30:00</th>
                  <td>BUSINESS SESSION</td>
                  <td>BALLROOM</td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">13:15:00</th>
                  <td>LUNCH BREAK</td>
                  <td>BALLROOM</td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">14:15:00</th>
                  <td>ACTIVITY</td>
                  <td>BALLROOM</td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">15:45:00</th>
                  <td>LEISURE TIME/ARTISTS UNPLUGGED-PREPARATION TIME</td>
                  <td>FOYER</td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">18:30:00</th>
                  <td>HIGHT TEA</td>
                  <td>BALLROOM</td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">19:30:00</th>
                  <td>R&R NIGHT</td>
                  <td>BALLROOM</td>
                  <td>SUITS/BLAZER/GOWNS/<br />DRESSES(BLACK OR RED)</td>
                </tr>
                <tr>
                  <th scope="row">21:30:00</th>
                  <td>DINNER + COCKTAIL</td>
                  <td>BALLROOM</td>
                  <td></td>
                </tr>
              </tbody>
              <thead>
                <tr className="table-head1">
                  <th scope="col" colSpan="4"><div className='heading'>DAY 3,APRIL 26<sup>th</sup></div></th>

                </tr>
              </thead>
              <tbody className="table-col">
                <tr>
                  <th scope="row">8:45:00</th>
                  <td>ASSEMBLE FOR EXCURSION</td>
                  <td>LOBBY / MAIN PORCH</td>
                  <td>COMFORTABLE CLOTHING</td>
                </tr>
                <tr>
                  <th scope="row">9:00:00</th>
                  <td>EXCURSION BEGINS</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">16:00:00</th>
                  <td>BACK TO ANANTARA AND RELAX</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">17:00:00</th>
                  <td>SAMURAI WINE AND CHEESE</td>
                  <td>ANANTARA <br /> BEACH</td>
                  <td>SHIRTS <br /> (PROVIDED BY TERUMO) <br /> CHINOS/JEANS</td>
                </tr>
                <tr>
                  <th scope="row">18:30:00</th>
                  <td>GET DRESSED FOR THE PARTY</td>
                  <td>BALLROOM</td>
                  <td>CASUAL CLOTHING</td>
                </tr>
                <tr>
                  <th scope="row">19:00:00</th>
                  <td>HIGHT TEA / WINE / BEER </td>
                  <td>BALLROOM</td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">19:30:00</th>
                  <td>ARTISTS UNPLUGGED</td>
                  <td>BALLROOM</td>
                  <td></td>
                </tr>
                <tr>
                  <th scope="row">23:30:00</th>
                  <td>AFTER PARTY "TALLI NIGHTS"</td>
                  <td>FOYER</td>
                  <td></td>
                </tr>
                <tr className="table-head1">
                  <th scope="col" colSpan="4"><div className='heading'>DAY 4,APRIL 27<sup>th</sup></div></th>

                </tr>
                <tr>
                  <th scope="row">8:30:00</th>
                  <td>BREAKFAST + CHECK-OUT AS PER SCHEDULE WITH A <br />
                    'THANKS' TO KICK OFF COMMIITTEE </td>
                  <td>OLU / MAIN LOBBY</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default Agenda