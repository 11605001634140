import React from 'react'

const AlertHandler = ({status,message}) => {
  return (
    <>
    <div className='absolute top-6 left-0 w-full z-20'>
      <div className='p-4'>
        <p className={`px-2 font-bold py-2 border-2 bg-white ${status ? 'border-green-600 text-green-600' : 'border-red-600 text-red-600'} rounded-md text-center`}>{message}</p>
      </div>
    </div>
    </>
  )
}

export default AlertHandler