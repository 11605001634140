import { Link } from 'react-router-dom';
import EventIcon from '../images/event.png';
import GalleryIcon from '../images/gallery.png';
import MemberIcon from '../images/member.png';
import ContactIcon from '../images/contact.png';
import AgendaIcon from '../images/agenda.png';
import HotelIcon from '../images/hotel.png';
import HomeBanner from '../images/banner.png';
import TravelIcon from '../images/travel-agency.png';
import DressIcon from '../images/dress-code.png';
import VideoIcon from '../images/video_icon.png';
import VisaIcon from '../images/visa.png';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
// import { getPages } from '../actions/pageAction';
import IconCard from '../Components/IconCard';
import Loader from '../Components/Loader';
import ErrorHandler from '../Components/Alert';


const Home = () => {
    const dispatch = useDispatch();
    const { loading, error, pages } = useSelector((state) => state.pages);

    useEffect(() => {
        // const user = JSON.parse(localStorage.getItem('user'));
        // dispatch(getPages(user?.id));
    }, [dispatch, error])

    return (
        <>
            {
                loading && <Loader />
            }
            {
                error && <ErrorHandler err={error} />
            }
            <div className="relative w-full h-full">
                <div className="w-full h-[200px] relative z-[1]">
                    <div className="absolute z-[-1] top-0 left-0 w-full h-full">
                        {/* <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-30"></div> */}
                        <img src={HomeBanner} className="w-full h-full object-contain bg-black mx-auto" alt='Home Banner' />
                    </div>
                    <div className="pt-20 px-4">
                        {/* <img src={Logo} alt='logo' className='mb-3 h-16 mx-auto' /> */}
                        {/* <p className='text-center text-white font-bold'>Sri Lanka</p>
                        <p className='text-center text-white'>Nature's Paradise Beckons</p> */}
                    </div>
                </div>
                <div className="relative bg-white menu-list z-[2] rounded-t-[25px] px-2 py-3">
                    <div className="flex flex-wrap text-center">
                        <Link to='/events' className="basis-1/3 px-1.5 py-1.5">
                            <div className="event-box rounded-lg py-2 px-2">
                                <img className="px-2 mx-auto h-16 object-contain" src={EventIcon} alt='Event Icon' />
                                <p className='text-xs sm:text-[8px] font-bold mt-1'>Events</p>
                            </div>
                        </Link>
                        <Link to='/agenda' className="basis-1/3 px-1.5 py-1.5">
                            <div className="event-box rounded-lg py-2 px-2">
                                <img className="px-2 mx-auto h-16 object-contain" src={AgendaIcon} alt='Agenda Icon' />
                                <p className='text-xs sm:text-[8px] font-bold mt-1'>Agenda</p>
                            </div>
                        </Link>
                        <Link to='/travel-advisory' className="basis-1/3 px-1.5 py-1.5">
                            <div className="event-box rounded-lg py-2 px-2">
                                <img className="px-2 mx-auto h-16 object-contain" src={TravelIcon} alt='Travel Icon' />
                                <p className='text-xs sm:text-[8px] font-bold mt-1'>Travel Advisory</p>
                            </div>
                        </Link>
                        <Link to='/dress-code' className="basis-1/3 px-1.5 py-1.5">
                            <div className="event-box rounded-lg py-2 px-2">
                                <img className="px-2 mx-auto h-16 object-contain" src={DressIcon} alt='Dress Icon' />
                                <p className='text-xs sm:text-[8px] font-bold mt-1'>Dress Code</p>
                            </div>
                        </Link>
                        <Link to='/hotels' className="basis-1/3 px-1.5 py-1.5">
                            <div className="event-box rounded-lg py-2 px-2">
                                <img className="px-2 mx-auto h-16 object-contain" src={HotelIcon} alt='Gallery Icon' />
                                <p className='text-xs sm:text-[8px] font-bold mt-1'>Hotels</p>
                            </div>
                        </Link>
                        <Link to='/member' className="basis-1/3 px-1.5 py-1.5">
                            <div className="event-box rounded-lg py-2 px-2">
                                <img className="px-2 mx-auto h-16 object-contain" src={MemberIcon} alt='Member icon' />
                                <p className='text-xs sm:text-[8px] font-bold mt-1'>KOC Members</p>
                            </div>
                        </Link>
                        <Link to='/contact' className="basis-1/3 px-1.5 py-1.5">
                            <div className="event-box rounded-lg py-2 px-2">
                                <img className="px-2 mx-auto h-16 object-contain" src={ContactIcon} alt='Contact Icon' />
                                <p className='text-xs sm:text-[8px] font-bold mt-1'>Contact Us</p>
                            </div>
                        </Link>
                        <Link to='/gallery' className="basis-1/3 px-1.5 py-1.5">
                            <div className="event-box rounded-lg py-2 px-2">
                                <img className="px-2 mx-auto h-16 object-contain" src={GalleryIcon} alt='Gallery Icon' />
                                <p className='text-xs sm:text-[8px] font-bold mt-1'>Gallery</p>
                            </div>
                        </Link>
                        <Link to='/videos' className="basis-1/3 px-1.5 py-1.5">
                            <div className="event-box rounded-lg py-2 px-2">
                                <img className="px-2 mx-auto h-16 object-contain" src={VideoIcon} alt='Gallery Icon' />
                                <p className='text-xs sm:text-[8px] font-bold mt-1'>Videos</p>
                            </div>
                        </Link>
                        <Link to='/eta' className="basis-1/3 px-1.5 py-1.5">
                            <div className="event-box rounded-lg py-2 px-2">
                                <img className="px-2 mx-auto h-16 object-contain" src={VisaIcon} alt='Gallery Icon' />
                                <p className='text-xs sm:text-[8px] font-bold mt-1'>ETA</p>
                            </div>
                        </Link>

                        {
                            pages &&
                            pages.map((page) => {
                                return <IconCard key={page.id} data={page} />
                            })
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export default Home