import { 
    CLEAR_ERRORS,
    LOAD_MEMBER_FAIL,
    LOAD_MEMBER_REQUEST, 
    LOAD_MEMBER_SUCCESS 
} from "../constants/memeberConstants";
import axios from "./axios";

export const getAllMembers = (auth_id) => async (dispatch) => {
    try {
        dispatch({ type: LOAD_MEMBER_REQUEST });

        const config = { headers: { "Content-Type": "application/json" }, params: { auth_id } };

        const { data } = await axios.get(
            `/members`,
            config
        );
        if (data?.status === true) {
            dispatch({ type: LOAD_MEMBER_SUCCESS, payload: data?.data });
            return
        }
        if (data?.status === false) {
            dispatch({ type: LOAD_MEMBER_FAIL, payload: data });
            return
        }
    } catch (error) {
        dispatch({ type: LOAD_MEMBER_FAIL, payload: error.response.data.message });
    }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
    setTimeout(() => {
        dispatch({ type: CLEAR_ERRORS });
    }, 5000);
};