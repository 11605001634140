import React, { useEffect, useState } from 'react'
import Back from '../Components/Back'
import { RiMessage2Line } from 'react-icons/ri'
import Loader from '../Components/Loader';
import axios from '../actions/axios';
import { Link } from 'react-router-dom';

const Notification = () => {
  const [loader, setLoader] = useState(false);
  const [notification, setNotification] = useState([]);
  const [alertBox, setAlertBox] = useState({});
  useEffect(()=>{
    (async()=>{
      try {
        setLoader(true);
        const user = JSON.parse(localStorage.getItem('user'));
        if(user){
          const auth_id = user.id;
          const { data } = await axios.get(
            '/notifications',
            {
              params:{
                auth_id
              }
            }
          );
          if(data?.status === true){
            setNotification({
              ...notification,
              item: data?.data
            })
            setLoader(false);
          }
        }
      } catch (err) {
        setLoader(true);
        console.log(err);
      }
    })()
  },[])

  return (
    <>
    {
      loader && <Loader />
    }
    <div className='relative w-full h-full pt-6 sm:pt-10'>
        <Back title="Notification" />
        <div className='w-full px-4'>
          {
            notification?.item &&
            notification?.item?.map((elem)=>{
              return <Link key={elem?.id} to={`/notification/${elem.id}`} className='block border-2 py-1 mt-3 px-3 rounded-lg border-green-500'>
                <p className='font-semibold'><RiMessage2Line className='inline text-green-500' /> {elem?.title}</p>
            </Link>
            })
          }
        </div>
    </div>
    </>
  )
}

export default Notification