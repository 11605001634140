import {
    ALL_HOTELS_FAIL,
    ALL_HOTELS_REQUEST,
    ALL_HOTELS_SUCCESS,
    CLEAR_ERRORS,
    HOTELS_FAIL,
    HOTELS_REQUEST,
    HOTELS_SUCCESS,
} from "../constants/hotelConstrants";

export const hotelReducer = (state = { hotels: [] }, action) => {
    switch (action.type) {
        case ALL_HOTELS_REQUEST:
            return {
                loading: true,
                hotels: []
            };
        case ALL_HOTELS_SUCCESS:
            return {
                ...state,
                loading: false,
                hotels: action.payload,
            };
        case ALL_HOTELS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};
export const hotelDetailReducer = (state = { hotels: [] }, action) => {
    switch (action.type) {
        case HOTELS_REQUEST:
            return {
                loading: true,
                hotels: {}
            };
        case HOTELS_SUCCESS:
            return {
                ...state,
                loading: false,
                hotels: action.payload,
            };
        case HOTELS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};