import React from 'react';
import Back from '../Components/Back';
import video1 from '../videos/video1.mp4';
import video2 from '../videos/video2.mp4';
import video3 from '../videos/video3.mp4';
import video1Screen from '../images/video1screen.png';
import video2Screen from '../images/video2screen.png';
import video3Screen from '../images/video3screen.png';

const Video = () => {
  return (
    <>

      <div className='relative w-full h-full pt-6 sm:pt-10'>
        <Back title="Videos" />
        <div className='video px-4 pb-3'>
          <div className="my-3">
            <video className='w-full h-[150px]' src={video3} poster={video3Screen} controls></video>
          </div>
          <div className="my-3">
            <video className='w-full h-[150px]' src={video1} poster={video1Screen} controls></video>
          </div>
          <div className="my-3">
            <video className='w-full h-[150px]' src={video2} poster={video2Screen} controls></video>
          </div>
        </div>
      </div>

    </>
  )
}

export default Video