import { Link } from "react-router-dom";
import Turtle from '../images/KickOff2024.png';

const IconCard = ({data}) => {
  return (
    <>
    <Link to={`/pages/${data?.slug}`} className="basis-1/3 px-1.5 py-1.5">
        <div className="event-box rounded-lg py-2 px-2">
            <img className="px-2 h-16 object-contain mx-auto" src={data?.icon || Turtle} alt='Contact Icon' />
            <p className='text-xs sm:text-[8px] font-bold mt-1'>{data?.title}</p>
        </div>
    </Link>
    </>
  )
}

export default IconCard