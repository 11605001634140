import Logo from '../images/Logo.png';
import { BsEnvelope } from 'react-icons/bs';
import { CiLock } from 'react-icons/ci';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, login } from '../actions/userAction';
import {Navigate} from 'react-router-dom';
import ErrorHandler from '../Components/Alert';

const Login = () => {
  const dispatch = useDispatch();

  const { isAuthenticated,error } = useSelector(
    (state) => state.user
  );

  const local_user = localStorage.getItem('user');

  useEffect(()=>{
    if (error) {
      dispatch(clearErrors());
    }
  },[dispatch,error])

  const [user, setUser] = useState({
      email: "",
      password: "",
  });

  const { email, password } = user;
  const loginSubmit = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  }
  return (
    <>
    {(isAuthenticated || local_user) ? <Navigate to='/home' /> : ''}
    
    {
      error && <ErrorHandler err={error} />
    }
    <div className="px-4 pt-16 h-full flex justify-center items-center flex-col">
        <img src={Logo} className='w-full max-w-44 mx-auto mb-5' alt='logo' />
        <form className="my-3" onSubmit={loginSubmit}>
          <h3 className='font-bold mb-4 text-xl'>Sign in</h3>
            <div>
              <label htmlFor='email' className="relative block mb-4">
                <BsEnvelope className='text-[#807A7A] absolute top-1/2 translate-y-[-50%] left-4' />
                <input 
                    type="email" 
                    name='email'
                    id='email' 
                    placeholder='abc@email.com' 
                    autoComplete='off' 
                    onChange={(e)=>{
                        setUser({
                            ...user,
                            email: e.target.value
                        })
                    }} 
                    value={ email || ''}
                    className='border-2 text-sm text-[#747688] border-[#CACACA] ps-10 pe-4 w-full py-3 rounded-xl' 
                />
              </label>

              <label htmlFor='password' className="relative block mb-4">
                <CiLock className='text-[#807A7A] absolute top-1/2 translate-y-[-50%] left-4' />
                <input 
                    type="password" 
                    name='password' 
                    id='password' 
                    value={password || ''} 
                    onChange={(e)=>{
                        setUser({
                            ...user,
                            password: e.target.value
                        })
                    }}
                    autoComplete='off'
                    placeholder='Your Password' 
                    className='border-2 text-sm text-[#747688] border-[#CACACA] ps-10 pe-4 w-full py-3 rounded-xl' 
                />
              </label>
                <button type='submit' className='bg-[#009368] text-md w-full rounded-lg py-3 font-medium text-white mb-4'>Sign In</button>
            </div>
        </form>
    </div>
    </>
  )
}

export default Login