export const ALL_EVENT_REQUEST = "ALL_EVENT_REQUEST";
export const ALL_EVENT_SUCCESS = "ALL_EVENT_SUCCESS";
export const ALL_EVENT_FAIL = "ALL_EVENT_FAIL";

export const EVENT_REQUEST = "EVENT_REQUEST";
export const EVENT_SUCCESS = "EVENT_SUCCESS";
export const EVENT_FAIL = "EVENT_FAIL";


export const ALL_PAGE_REQUEST = "ALL_PAGE_REQUEST";
export const ALL_PAGE_SUCCESS = "ALL_PAGE_SUCCESS";
export const ALL_PAGE_FAIL = "ALL_PAGE_FAIL";


export const PAGE_REQUEST = "PAGE_REQUEST";
export const PAGE_SUCCESS = "PAGE_SUCCESS";
export const PAGE_FAIL = "PAGE_FAIL";


export const CLEAR_ERRORS = "CLEAR_ERRORS";
