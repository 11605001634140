import React from 'react'
import Back from '../Components/Back'
import Banner from '../images/dress-banner.png';

export const Dress = () => {
  return (
    <>
        
      <div className='relative w-full h-full pt-6 sm:pt-10'>
        <Back title="Dress Code" />
        <div className="w-full box-wrap overflow-y-scroll pb-3">
          <img src={Banner} />
          <div className="py-2 px-4">
            <h2 className="text-md font-bold"> Day1</h2>
            <div className="mt-3">
              <h4 className="text-sm font-semibold mb-2">Evening:</h4>
              <ul className="list-disc ps-6 text-xs font-semibold text-[#747688]">
                <li className="my-1">Business Casual</li>
                <li className="my-1">Collar T-Shirt or Shirt & Jeans or Trouser</li>
              </ul>
            </div>
          </div>
          <div className="py-2 px-4">
            <h2 className="text-md font-bold">Day2</h2>
            <div className="mt-3">
              <h4 className="text-sm font-semibold mb-2">Morning:</h4>
              <ul className="list-disc ps-6 text-xs font-semibold text-[#747688]">
                <li className="my-1">Terumo T-Shirt & Jacket</li>
              </ul>
            </div>
            <div className="mt-3">
              <h4 className="text-sm font-semibold mb-2">Evening:</h4>
              <ul className="list-disc ps-6 text-xs font-semibold text-[#747688]">
                <li className="my-1">Blazer/Suits | Formal Gowns/Dress (Black or Red - Preferably)</li>
              </ul>
            </div>
          </div>
          <div className="py-2 px-4">
            <h2 className="text-md font-bold">DAY 3</h2>
            <div className="mt-3">
              <h4 className="text-sm font-semibold mb-2">Morning:</h4>
              <ul className="list-disc ps-6 text-xs font-semibold text-[#747688]">
                <li className="my-1">Comfortable clothing</li>
              </ul>
            </div>
            <div className="mt-3">
              <h4 className="text-sm font-semibold mb-2">Evening:</h4>
              <ul className="list-disc ps-6 text-xs font-semibold text-[#747688]">
                <li className="my-1">Casual Clothing</li>
              </ul>
            </div>
          </div>
          <div className='py-2 px-4'>
            <p className='text-center font-bold text-xs'>Hope you have a Turtley Awesome Time <br /> Contact Kickoff committee in case of queries</p>
          </div>
        </div>
      </div>
    </>
  )
}
