import { useEffect, useState } from "react";
import Back from "../Components/Back";
import axios from "../actions/axios";

const Contact = () => {
    const [contactForm, setContactForm] = useState({ name: [], phone: [], email: [], message: [] });
    const [errContactForm, setErrContactForm] = useState({ name: '', phone: '', email: '', message: '',success: {} });
    const [contactBtn, setContactBtn] = useState(false);

    async function submitFormHandler(e) {
        e.preventDefault();
        const { name, phone, email, message } = contactForm;
        const user = JSON.parse(localStorage.getItem('user'));
        const auth_id = user?.id;
        try {
            setContactBtn(true);
            const { data } = await axios.post('/contact-submit', { auth_id, name, email, phone, message });
            if (data?.status === true) {
                setErrContactForm({
                    ...errContactForm,
                    success: {status: data?.status,message: data?.message}
                })
                setContactBtn(false)
                return;
            }
            if (data?.status === false) {
                setContactBtn(false)
                setErrContactForm({
                    ...errContactForm,
                    name: data?.errors?.name,
                    email: data?.errors?.email,
                    phone: data?.errors?.phone,
                    message: data?.errors?.message,
                    success: data?.status,
                })
                return;
            }
        } catch (error) {
            setContactBtn(false)
            console.log(error);
        }
    }
    return (
        <>
            <div className='relative w-full h-full sm:pt-10'>
                <Back title="Contact Us" />
                <div className="w-full box-wrap overflow-y-scroll px-4 pb-3">
                    {
                        errContactForm?.success?.status && <p className="text-xs border-green-600 text-center border-2 rounded-md py-2 px-2 text-green-600 font-semibold">{errContactForm?.success?.message}</p>
                    }
                    <form onSubmit={submitFormHandler}>
                        <div className="mb-4 mt-3">
                            {
                                errContactForm?.name && 
                                errContactForm?.name.map((err,index)=>{
                                    return(<p key={index} className="text-xs text-red-600 font-semibold">{err}</p>)
                                })
                            }
                            <label htmlFor="name" className="block mb-2 text-sm font-semibold leading-[18px] text-[#222222]">Name</label>
                            <input
                                type="text"
                                id="name"
                                autoComplete='off'
                                className="border border-[#CACACA] text-[#747688] text-sm rounded focus:ring-[#009368] focus:border-[#009368] block w-full px-3 py-1.5"
                                placeholder="Your Full Name"
                                value={contactForm.name}
                                onChange={(e) => {
                                    setErrContactForm({ ...errContactForm, name: null })
                                    setContactForm({ ...contactForm, name: e.target.value })
                                }}
                            />
                        </div>
                        <div className="mb-4">
                            {
                                errContactForm?.phone &&
                                errContactForm?.phone.map((err,index)=>{
                                    return(<p key={index} className="text-xs text-red-600 font-semibold">{err}</p>)
                                })
                            }
                            <label
                                htmlFor="phone"
                                className="block mb-2 text-sm font-semibold leading-[18px] text-[#222222]"
                            >Mobile number</label>
                            <input
                                type="text"
                                autoComplete='off'
                                id="phone"
                                className="border border-[#CACACA] text-[#747688] text-sm rounded focus:ring-[#009368] focus:border-[#009368] block w-full px-3 py-1.5"
                                placeholder="+91"
                                value={contactForm.phone}
                                onChange={(e) => {
                                    setErrContactForm({ ...errContactForm, phone: null })
                                    setContactForm({ ...contactForm, phone: e.target.value })
                                }}
                            />
                        </div>
                        <div className="mb-4">
                            {
                                errContactForm?.email &&
                                errContactForm?.email.map((err,index)=>{
                                    return(<p key={index} className="text-xs text-red-600 font-semibold">{err}</p>)
                                })
                            }
                            <label
                                htmlFor="email"
                                className="block mb-2 text-sm font-semibold leading-[18px] text-[#222222]"
                            >Email</label>
                            <input
                                type="text"
                                id="email"
                                value={contactForm.email}
                                onChange={(e) => {
                                    setErrContactForm({ ...errContactForm,email: undefined })
                                    setContactForm({ ...contactForm, email: e.target.value })
                                }}
                                className="border border-[#CACACA] text-[#747688] text-sm rounded focus:ring-[#009368] focus:border-[#009368] block w-full px-3 py-1.5"
                                placeholder="Email address"
                            />
                        </div>
                        <div className="mb-5">
                            {
                                errContactForm?.message &&
                                errContactForm?.message.map((err,index)=>{
                                    return(<p key={index} className="text-xs text-red-600 font-semibold">{err}</p>)
                                })
                            }
                            <label htmlFor="message" className="block mb-2 text-sm font-semibold leading-[18px] text-[#222222]">Notes</label>
                            <textarea
                                id="message"
                                onChange={(e) => {
                                    setErrContactForm({...errContactForm,message:undefined})
                                    setContactForm({ ...contactForm, message: e.target.value })
                                }}
                                className="border resize-none border-gray-300 text-[#747688] text-sm rounded outline-[#009368] w-full p-2.5" placeholder="Write a notes..."></textarea>
                        </div>
                        <button type="submit" className="text-black hover:text-white border-2 transition border-[#009368] hover:bg-[#009368] font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center" disabled={contactBtn}>{contactBtn ? 'Loading...' : 'SEND'}</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Contact