import { useEffect, useState } from 'react'
import Back from '../Components/Back'
import { FaRegFolder } from 'react-icons/fa6'
import axios from '../actions/axios';
import { Link } from 'react-router-dom';
import Loader from '../Components/Loader';

const GalleryFolder = () => {
    const [loader, setLoader] = useState(true);
    const [folders, setFolders] = useState({});
    useEffect(() => {
        (async () => {
            try {
                setLoader(true);
                const user = JSON.parse(localStorage.getItem('user'));
                if (user) {
                    const auth_id = user.id;
                    const data = await axios.get('/get-gallery-dates', { params: { auth_id } });
                    if (data?.data?.status === false) {
                        console.log(data);
                        return;
                    }
                    if (data?.data?.status === true) {
                        setFolders({ ...folders, items: data?.data?.data });
                        setLoader(false);
                    }
                }
            } catch (err) {
                console.log(err);
            }
        })();
    }, [])
    return (
        <>
            {
                loader && <Loader />
            }
            <div className='relative w-full h-full sm:pt-10'>
                <Back title="Gallery" />
                <div className="w-full box-wrap overflow-y-scroll pb-3">
                    <div className='flex flex-wrap'>
                        {
                            folders?.items &&
                            folders?.items.map((item, index) => {
                                return <div key={index} className="basis-1/2 px-1.5 py-1.5">
                                    <Link to={item?.date} className="flex flex-wrap relative">
                                        <div className='w-full h-[120px] flex justify-center items-center flex-col border-2 border-[#eee] relative'>
                                            <div className='relative'>
                                                <FaRegFolder className='text-5xl text-gray-500' />
                                                <span className='absolute top-1/2 font-bold left-1/2 -translate-x-1/2 -translate-y-1/2 text-gray-500 text-sm'>{item?.count}</span>
                                            </div>
                                            <p className='text-sm mt-2 font-bold text-gray-500'>{item?.date}</p>
                                        </div>
                                    </Link>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default GalleryFolder