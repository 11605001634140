import Axios from 'axios';
import { API_URL, X_API_KEY } from '../Utils/General';

const axios = Axios.create({
	baseURL: API_URL,
	withCredentials: true,
	headers: {
		"Content-Type": "application/json",
		"Accept": "application/json",
        "x-api-key": X_API_KEY	
	},
});

export default axios;