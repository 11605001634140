import { useEffect, useState } from 'react'
import Back from '../Components/Back';
import { useDispatch, useSelector } from 'react-redux';
import HotelBox from '../Components/HotelBox';
import Loader from '../Components/Loader';
import { getAllHotel } from '../actions/hotelAction';
import HotelConfirmation  from '../images/hotel-confirmation.jpg';
import { IoClose } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { FaDownload } from 'react-icons/fa6';

const Hotels = () => {
    const dispatch = useDispatch();

    const { loading, error, hotels } = useSelector(
        (state) => state.hotel
    );
    const [preview, setPreview] = useState({ active: false });
    
  function showImage(e) {
    setPreview({
      ...preview,
      active: true,
      src: e.target.src,
      alt: e.target.alt,
    })
  }

  function closePreview() {
    setPreview({ ...preview, active: false });
  }
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        const auth_id = user.id;
        dispatch(getAllHotel(auth_id));

    }, [dispatch, error])

    return (
        <>
            {
                loading && <Loader />
            }
            <div className='relative w-full h-full sm:pt-10 px-3'>
                <Back title="Hotels" />
                {
                    hotels.map((item) => {
                        return <HotelBox key={item.id} hotel={item} />
                    })
                }
                <div className='my-4'>
                    <h2 className='text-lg font-bold mb-3'>Hotel Confirmation</h2>
                    <img className='h-32 w-full object-contain' src={HotelConfirmation} onClick={showImage} alt='Hotel Confirmation Form' />
                </div>
                <div className='video mt-5'>
                    <h2 className='text-lg font-bold mb-3'>Videos</h2>
                    <div className="my-3">
                        <iframe width="100%" height="180" src="https://www.youtube.com/embed/CgwGpaI-eqE?si=ZBYRxYO60soAOotC" title="YouTube video player" frameBorder="0" allow="autoplay; encrypted-media; picture-in-picture;" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </div>
                    <div className="my-3">
                        <iframe width="100%" height="180" src="https://www.youtube.com/embed/8S-WCHelqZA?si=PZwNZ5N1F768zbFC" title="YouTube video player" frameBorder="0" allow="autoplay; encrypted-media; picture-in-picture;" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </div>
                    <div className="my-3">
                        <iframe width="100%" height="180" src="https://www.youtube.com/embed/tKJV_Bm8Ndg?si=l1V_QeMfArzsfZzD" title="YouTube video player" frameBorder="0" allow="autoplay; encrypted-media; picture-in-picture;" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </div>
                    <div className="my-3">
                        <iframe width="100%" height="180" src="https://www.youtube.com/embed/1qeimXT9Uh8?si=-cKNGYqCkajtLECZ" title="YouTube video player" frameBorder="0" allow="autoplay; encrypted-media; picture-in-picture;" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
            
        <div className={`absolute z-30 top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-60 ${(preview.active) ? '' : 'hidden'}`}>
          <div className="w-full mx-5 relative">
          <div className='absolute w-8 h-8 bg-white -top-3 -left-3 flex justify-center items-center shadow-xl z-[2] rounded-full'>
              <Link to={preview?.src} target='_blank'>
                <FaDownload />
              </Link>
            </div>
            <div onClick={closePreview} className="absolute w-8 h-8 bg-white -top-3 -right-3 flex justify-center items-center shadow-xl z-[2] rounded-full">
              <IoClose className='text-lg' />
            </div>
            <img className='w-full bg-white max-h-screen object-contain p-3 rounded-xl' src={preview?.src} alt={preview?.alt} />
          </div>
        </div>
        </>
    )
}

export default Hotels