import { configureStore } from '@reduxjs/toolkit'
import { userReducer } from './reducers/userReducer';
import { eventDetailReducer, eventReducer, pageReducer } from './reducers/pageReducer';
import { galleryReducer } from './reducers/galleryReducer';
import { memberReducer } from './reducers/memberReducer';
import { hotelDetailReducer, hotelReducer } from './reducers/hotelReducer';


const store = configureStore({
  reducer: {
    user: userReducer,
    event: eventReducer,
    event_detail: eventDetailReducer,
    pages: pageReducer,
    gallery: galleryReducer,
    member: memberReducer,
    hotel: hotelReducer,
    hotel_detail: hotelDetailReducer,
  },
})

export default store;